<template>
  <div class="d-flex">
    <table class="table table-striped">
      <thead>
        <tr>
          <th
            class="text-center text-truncate"
            v-for="(state, index) in tableData.head"
            :key="index"
            >{{ state }}</th
          >
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData.body" :key="index">
          <td class="text-center">{{ indexes + index }}</td>
          <td v-if="!isPreview">
            <router-link
              :to="{
                path: `/crewing/profile`,
                query: {
                  user: 'crew',
                  id: data.id,
                  preview: isPreview,
                },
              }">{{ data.fullname }}</router-link><br />
              <small style="color: gray">NIP : <b>{{ data?.nip ?? '-' }}</b><br />Seaferer Code :{{ data?.personal_data?.seaferer_code ?? '-' }}</small><br />
              <small class="text-danger truncate" v-if="data?.is_delete">Waiting approval to delete this crew</small>
          </td>
          <td v-else>
            <router-link
              target="_blank"
              :to="{
                path: `/crewing/profile`,
                query: {
                  user: 'crew',
                  id: data.id,
                  preview: isPreview,
                },
              }">{{ data.fullname }}</router-link><br />
              <small style="color: gray">NIP : <b>{{ data?.nip ?? '-' }}</b><br />Seaferer Code :{{ data?.personal_data?.seaferer_code ?? '-' }}</small><br />
              <small class="text-danger truncate" v-if="data?.is_delete">Waiting approval to delete this crew</small>
          </td>
          <td class="text-center">{{ data?.vehicle?.name ?? '-' }}<br /><small style="color: gray">{{
              data?.company?.company ?? '-'
            }}</small><br />
             <b-badge
              variant="primary d-block"
              v-if="data?.back_up_crew"
            >Backup Crew</b-badge>
          </td>
          <td class="text-center">{{ data?.position?.name ?? '-' }} </td>
          <td class="text-center"
            >{{ data?.join_on_board ? moment(data?.join_on_board).format('DD MMM YYYY HH:mm') : '-' }}
            <b-badge
              :variant="
                joinOnBoardStatus(data) === 'More than 1 year'
                  ? 'success d-block'
                  : joinOnBoardStatus(data) === 'More than 6 month'
                  ? 'primary d-block'
                  : joinOnBoardStatus(data) === 'More than 3 month'
                  ? 'warning d-block'
                  : 'dark d-block'
              "
              v-if="joinOnBoardStatus(data)"
              >{{ joinOnBoardStatus(data) }}
            </b-badge></td
          >
          <td class="text-center">{{ data?.sign_on ? moment(data?.sign_on).format('DD MMM YYYY HH:mm') : '-' }}</td>
          <td class="text-center">{{ data?.sign_off ? moment(data?.sign_off).format('DD MMM YYYY HH:mm') :  '-' }} <br /> </td>
          <td class="text-center">
            <b-badge class="mb-1" :variant="`${data?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
              >{{ data?.status_emp_crewing?.name ?? '-' }}
            </b-badge>
            <b-badge variant="danger d-block" v-if="expiredContract(data)"
              >{{ expiredContract(data) }}
            </b-badge>
          </td>
          <td class="text-center" v-if="!isPreview">
            <b-dropdown
              size="lg"
              variant="link iq-bg-primary iq-border-radius-10"
              toggle-class="text-decoration-none"
              no-caret
              class="dropdown-index"
            >
              <template v-slot:button-content>
                <span
                  class="dropdown-toggle p-0"
                  id="dropdownMenuButton5"
                  data-toggle="dropdown"
                >
                  <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                </span>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.swap-position
                variant="primary"
                @click="() => setDataUser(data)"
                ><i class="fas fa-arrow-circle-down mr-2"></i>Swap Position</b-dropdown-item>  

              <b-dropdown-item
                href="#"
                v-b-modal.notice_sign_off
                variant="primary"
                @click="() => setDataUser(data)"
              >
                <i class="fas fa-arrow-circle-down mr-2"></i>Notice Sign Off
              </b-dropdown-item>
              
              <b-dropdown-item
                href="#"
                v-b-toggle.sign-off
                variant="primary"
                @click="() => setDataUser(data)"
                ><i class="fas fa-arrow-circle-down mr-2"></i>Sign
                Off</b-dropdown-item>

              <b-dropdown-item v-if="!data.status_condite_time">
                <router-link
                  :to="{
                    path: data?.position_id === 1 || data?.position_id  === 2 ? `/crewing/condite/${data.id}` : `/crewing/conduct/${data.id}` ,
                    params: { id: data.id },
                  }"
                >
                  <i class="fas fa-arrow-circle-down mr-2"></i>Penilaian
                  Conduite</router-link
                >
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                variant="primary"
                @click="() => handleDownloadPKL(data.id, data.fullname)"
                ><i class="fas fa-file mr-2"></i>Download Surat
                PKL</b-dropdown-item
              >
              <b-dropdown-divider />
              <b-dropdown-item
                href="#"
                v-b-toggle.blacklist-crew
          
                @click="() => setDataUser(data)"
                variant="danger"
                ><i class="fa fa-ban mr-2"></i>Black List</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-b-modal.delete-crew
                @click="() => setDataUser(data)"
                variant="danger"
                ><i class="fa fa-user mr-2"></i>Delete Crew</b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  data() {
    return {
      key: 'crew',
    }
  },
  props: {
    indexes: {
      type: Number,
      default: 1,
    },  
    isPreview: {
      type: Boolean,
      default: false,
    }, 
    currentPage: {
      type: Number,
      default: false,
    },
    tableData: {
      type: Object,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          key: '',
        }
      },
    },
  },
  computed: {},
  methods: {
    ...crewingActions,
    setDataUser(state) {
      this.form.id = state.id
      this.form.key = 'crew'
    },
    joinOnBoardStatus(data) {
      if (!data.join_on_board) return ''

      if (data.working_period > 2) {
        data.status_condite_time = true
      }

      if (data.working_period > 12) {
        return 'More than 1 year'
      } else if (data.working_period > 6) {
        return 'More than 6 month'
      } else if (data.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },

    expiredContract(data) {
      return moment(data.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async handleDownloadPKL(id, name) {
      const res = await this.fetchDownloadPKL({ id })
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `File PKL - ${name}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>