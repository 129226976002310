var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-sidebar"
  }, [_c('div', {
    staticClass: "iq-sidebar-logo d-flex justify-content-between mb-5"
  }, [_c('router-link', {
    attrs: {
      "to": _vm.homeURL
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "200px",
      "height": "80px"
    },
    attrs: {
      "src": _vm.logo,
      "alt": "logo"
    }
  })]), _vm.toggleButton ? _c('div', {
    staticClass: "iq-menu-bt-sidebar"
  }, [_c('div', {
    staticClass: "iq-menu-bt align-self-center"
  }, [_c('div', {
    staticClass: "wrapper-menu",
    class: {
      'open': _vm.isMini
    },
    on: {
      "click": _vm.miniSidebar
    }
  }, [_vm._m(0), _vm._m(1)])])]) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "margin-top": "-20px"
    },
    attrs: {
      "id": "sidebar-scrollbar"
    }
  }, [_c('nav', {
    staticClass: "iq-sidebar-menu",
    class: _vm.horizontal ? 'd-xl-none' : ''
  }, [_c('List', {
    attrs: {
      "items": _vm.items,
      "open": true,
      "horizontal": _vm.horizontal
    }
  })], 1), _c('div', {
    staticClass: "p-3"
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-circle"
  }, [_c('i', {
    staticClass: "ri-arrow-left-s-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hover-circle"
  }, [_c('i', {
    staticClass: "ri-arrow-left-s-line"
  })]);

}]

export { render, staticRenderFns }