import { render, staticRenderFns } from "./MasterPortStay.vue?vue&type=template&id=60cac584&scoped=true&"
import script from "./MasterPortStay.vue?vue&type=script&lang=js&"
export * from "./MasterPortStay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60cac584",
  null
  
)

export default component.exports